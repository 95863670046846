<template>
  <div class="aboutUs">
    <div class="top">
      <div class="content">
        <div class="con">
          <div class="text1">Contact us</div>
          <div class="text2">Modern Dental Denture High Tech Group</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="map">
        <img src="../assets/about.png" alt="" />
      </div>
      <div class="information"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  overflow: hidden;
  .top {
    width: 100%;
    height: 500px;
    background: url("../assets/banner2.png") no-repeat 100% 50%;
    background-size: 100%;
    .content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .con {
        width: 800px;
        margin: 180px auto;
        color: #fff;
        .text1 {
          font-size: 50px;
          margin-bottom: 10px;
          text-align: center;

          font-weight: 600;
        }
        .text2 {
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    .map {
      margin: 70px 0;
      img {
        width: 100%;
      }
    }
    .information {
      width: 100%;
      // height:600px;
    }
  }
}
</style>